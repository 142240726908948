import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import CustomButton from "./CustomButton";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import ContainerheaderMainNav from "./containerheader-main-nav";

const animationFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  `;
const animationScaleUp = keyframes`
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
`;
const IdmLogo22Icon = styled.img`
  width: 11.669rem;
  position: relative;
  height: 5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const ImageLogo = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const UnlockingPotential = styled.span`
  margin: 0;
`;
const MediumLengthHeroContainer = styled(Typography)`
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 200;
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  text-transform: uppercase;
  font-family: var(--text-regular-light);
  color: var(--text-alternate);
  white-space: pre-wrap;
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  @media screen and (max-width: 960px) {
    padding-right: 0rem;
    box-sizing: border-box;
  }
`;
const Actions = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-base) 0rem 0rem;
  gap: var(--gap-base);
  @media screen and (max-width: 960px) {
    padding-left: 0rem;
    box-sizing: border-box;
  }
`;
const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-161xl) var(--padding-21xl) var(--padding-211xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  opacity: 1;
  max-width: 43.75rem;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationScaleUp};
  }
  @media screen and (max-width: 960px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const HeroRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url("/hero--30@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  opacity: 0;
  text-align: center;
  font-size: var(--heading-desktop-h1-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationFadeIn};
  }
  @media screen and (max-width: 960px) {
    padding-left: 0rem;
    box-sizing: border-box;
  }
`;

const SectionHero = () => {
  const [navLinkDropdown1AnchorEl, setNavLinkDropdown1AnchorEl] =
    useState(null);
  const navLinkDropdown1Open = Boolean(navLinkDropdown1AnchorEl);
  const handleNavLinkDropdown1Click = (event) => {
    setNavLinkDropdown1AnchorEl(event.currentTarget);
  };
  const handleNavLinkDropdown1Close = () => {
    setNavLinkDropdown1AnchorEl(null);
  };

  const onButtonmuitext01Click = useCallback(() => {
    navigate("/");
  }, []);

  const onNavLinkDropdown1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButtonmuitext02Click = useCallback(() => {
    navigate("/the-mankayan-project");
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onButtonClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='intro1Section']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onButton1Click = useCallback(() => {
    navigate("/investor-updates");
  }, []);

  return (
    <HeroRoot data-animate-on-scroll>
      <ContainerheaderMainNav
        onButtonmuitext01Click={onButtonmuitext01Click}
        onNavLinkDropdown1Click={onNavLinkDropdown1Click}
        onButtonmuitext02Click={onButtonmuitext02Click}
        showButtonmuicontainedTex={false}
        showMenuItem={false}
        showButtonmuitextIcon={false}
      />
      <Column data-animate-on-scroll>
        <Content>
          <ImageLogo>
            <IdmLogo22Icon alt="" src="/idmlogo2-22.svg" />
          </ImageLogo>
          <MediumLengthHeroContainer variant='h1' sx={{fontWeight: '600', fontStyle: 'normal'}} >
            <UnlockingPotential>{`Unlocking Potential: `}</UnlockingPotential>
            <UnlockingPotential>The Mankayan Project</UnlockingPotential>
          </MediumLengthHeroContainer>
          <LoremIpsumDolor>
            Discover the vast copper-gold porphyry project in THE MINING
            FRIENDLY JURISDICTION OF Northern Luzon IN THE PHILIPPINES,
            compliant with JORC 2012 reporting standards and backed by a 25-year
            mining license.
          </LoremIpsumDolor>
        </Content>
        <Actions>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={onButtonClick}
          >
            Project Info
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={onButton1Click}
          >
            Investor Info
          </CustomButton>
          <CustomButton
            variant="outlined"
            color="primary"
            isChatButton={true}
          >
            Get in Touch
          </CustomButton>
        </Actions>
      </Column>
    </HeroRoot>
  );
};

export default SectionHero;
