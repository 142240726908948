import * as React from "react";
import { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import styled, { keyframes } from "styled-components";

const animationSlideInLeft = keyframes`
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `;
const Subheading = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const MankayanCopperGold = styled.p`
  margin: 0;
`;
const Heading = styled(Typography)`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 400;
  font-family: inherit;
`;
const TheMankayanProject = styled.p`
  margin-block-start: 0;
  margin-block-end: 16px;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--text-alternate);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Button1 = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 300;
`;
const IconChevronRight = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const Button2 = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const Button3 = styled(Button)``;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) 0rem 0rem;
  gap: var(--gap-5xl);
  font-size: var(--text-medium-light-size);
  color: var(--border-primary);
`;
const Content1 = styled.div`
  width: 41rem;
  height: 28.063rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  opacity: 0;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInLeft};
  }
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
    height: auto;
  }
`;
const Intro1SectionRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  background-image: url("/intro1-section@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--text-regular-light-size);
  color: var(--text-alternate);
  font-family: var(--text-regular-light);
`;

const Intro1Section = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <Intro1SectionRoot data-scroll-to="intro1Section">
      <Content1 data-animate-on-scroll>
        <SectionTitle>
          <Subheading>About</Subheading>
          <Content>
            <Heading variant='h2'>
              <MankayanCopperGold>{`MANKAYAN COPPER-GOLD `}</MankayanCopperGold>
              <MankayanCopperGold>PORPHYRY PROJECT</MankayanCopperGold>
            </Heading>
            <Text1>
              <TheMankayanProject>{`The Mankayan Project is one of the largest undeveloped copper-gold porphyry deposits globally, boasting a 25-year mining license (MPSA), renewed on March 4 2022. `}</TheMankayanProject>
              <MankayanCopperGold>{`Situated in Northern Luzon, it is strategically located near the heart of the Mankayan mineral district, renowned for hosting significant copper-gold deposits and prospects. `}</MankayanCopperGold>
            </Text1>
          </Content>
        </SectionTitle>
        <Actions>
          <Button2>
            <Button1>Sign Up</Button1>
            <IconChevronRight alt="" src="/icon--chevronright1.svg" />
          </Button2>
          <Button3
            disableElevation={true}
            color="primary"
            variant="contained"
            onClick={() => {
              document.getElementById('stats-section').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Learn More
          </Button3>
        </Actions>
      </Content1>
    </Intro1SectionRoot>
  );
};

export default Intro1Section;
